@font-face {
  font-family: 'Open Sans' ;
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: regular;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans' ;
  src: url('/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'Open Sans' ;
  src: url('/fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: italic;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans' ;
  src: url('/fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family:'Poppins' ;
  src: url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Streamfizz';
  src: url('/fonts/player/streamfizz.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Streamfizz';
  src: url('/fonts/player/streamfizz.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family:'Roboto' ;
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'Roboto-Bold' ;
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}

body {
  font-family: 'Open Sans','Open Sans Regular','Open Sans Bold' sans-serif !important;
  background: #eff2f5;
}

h1,
h2,
h3 {
  font-weight: 600;
}

.ant-modal-header {
  border: none !important;
  padding-bottom: 0;
}
.ant-modal-title {
  font-size: 20px;
}
.ant-modal-content {
  width: auto !important;
  height: 100%;
}

.ant-message {
  top: initial;
  bottom: 0;
}

.ant-message-notice {
  text-align: right;
}

.ant-message-notice-content {
  background: rgba(255, 255, 255, 0.9) !important;
}

.ant-message .anticon {
  display: none;
}

.ant-comment-actions {
  margin-top: 2px !important;
}
.ant-comment-content-detail {
  font-size: 13px;
}
.comment-action {
  padding-left: 4px;
  cursor: 'auto';
}

.ant-comment-inner {
  padding: 0 !important;
}

.ant-form-item-explain {
  font-size: 12px;
  line-height: 1.2;
  min-height: initial;
}

.ant-input-textarea {
  position: relative;
}
.ant-form-item .ant-input-textarea-show-count::after {
  margin: 0;
  position: absolute;
  top: 0px;
  right: 2px;
  font-size: 8px;
}

.ant-collapse-arrow {
  position: absolute;
}

.ant-layout-sider {
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out,
    flex 0.3s ease-in-out !important;
}
.errorImage span {
  height: 100vh !important;
 }

 .ant-badge {
  color: inherit !important;

 }

 .ant-badge-count {
  min-width: 16px;
    height: 16px;
    padding: 1px;
    font-size: 12px;
    line-height: 13px;
    border-radius: 50%;
    font-weight: 900;
    box-shadow: none !important;
 }

 .tarteaucitronSelfLink,.tarteaucitronReadmoreInfo,.tarteaucitronReadmoreSeparator:nth-child(1){
  display: none !important;
 }
 .ant-modal-title{
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
 }
 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  text-decoration: underline !important;
}
.ant-tabs-tab:hover {
  color: white !important;
}